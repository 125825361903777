import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import BecomeACreator from "./pages/become_creator";
import Navbar from "./components/Navbar";
import LandingPage from "./pages/LandingPage";
import AboutUs from "./pages/about_us";
import ContactUs from "./pages/contact-us";
import NotFoundPage from "./pages/NotFoundPage";
import Careers from "./pages/careers";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navbar />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/become-creator",
        element: <BecomeACreator />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/game-demo",
        element: <Navigate to={"/"} />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
