import React from "react";
import { Link } from "react-router-dom";
type props = {
  image: string;
  heading: string;
  para: string;
};

const Card = ({ image, heading, para }: props) => {
  return (
    <div className=" h-full w-full  bg-[#272639] rounded-md flex flex-wrap flex-col sm:m-8 sm:w-[12rem] sm:h-[13.5rem]">
      <div className="p-1">
        <img src={image} alt="" className="object-contain" />
      </div>
      <div className="p-2 flex flex-col  flex-wrap">
        <h4 className="text-[#E6E4ED]">{heading}</h4>
        <p className="text-[0.62rem] h-[3rem] sm:text-[0.5rem]  text-[#ACAAB2]">
          {para}
        </p>
        <Link
          to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
          target="_blank"
          className="text-[#39FF14] text-xs"
        >
          Download The App
        </Link>
      </div>
    </div>
  );
};

export default Card;
