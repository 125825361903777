import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineCloseCircle } from "react-icons/ai";
import { Outlet, Link, useLocation } from "react-router-dom";
import FooterItem from "./FooterItem";
import PrivacyPolicy from "./PrivacyPolicy";
const creatorLinks = [
  { label: "Creator Login", link: "https://admin.onthehash.com/" },
  { label: "Apply To Be a Creator", link: "/become-creator" },
];
const aboutLiks = [
  { label: "About Us", link: "/about-us" },
  { label: "Careers", link: "/careers" },
];
const contactusLinks = [
  {
    link: "/contact-us",
    label: "Contact Us",
  },
];
const privacyLinks = [
  {
    link: "#footer",
    label: "Privacy Policy",
  },
];
const Navbar = () => {
  const { pathname } = useLocation();
  const [showNavItems, setShowNavItems] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const handleLinkClick = (privacyDialogue?: boolean) => {
    if (!privacyDialogue) {
      return window.scrollTo(0, 0);
    }
    return setShowPrivacyPolicy(true);
  };
  return (
    <div
      className={`${showNavItems ? "overflow-hidden h-[calc(100dvh)]" : ""}  ${
        pathname === "/about-us"
          ? "bg-[#151521] md:bg-aboutpage  "
          : "bg-hero  "
      } flex justify-center bg-contain  bg-[#151521]    bg-no-repeat ${
        pathname === "/contact-us" && " bg-cover"
      } ${pathname === "/careers" && " bg-cover"} ${
        pathname === "/openings" && " bg-cover"
      }
      ${pathname === "/support" && " bg-cover"}
      ${pathname === "/advertise" && " bg-cover"}
       `}
    >
      <div
        className="w-full flex flex-col"
        style={{
          background: "linear-gradient( #15152105, #151521 )",
        }}
      >
        <div className="flex p-2 items-center justify-between ">
          <h3 className="pl-2">
            <Link to={"/"} className="flex flex-col gap-1 ">
              <span className="text-xl text-white font-bold ">ONTHE</span>

              <span className="leading-3 ml-3 font-bold text-4xl text-white">
                HASH
              </span>
            </Link>
          </h3>
          <div className="hidden md:block">
            <Link
              to={"/become-creator"}
              className="border border-[#737281] text-white p-2 rounded-sm text-xs mr-4"
            >
              CONTENT CREATOR
            </Link>
            <Link
              to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
              target="_blank"
              className=" bg-green-main  p-2 rounded-sm text-xs"
            >
              DOWNLOAD NOW
            </Link>
          </div>
          <div
            onClick={() => setShowNavItems((p) => !p)}
            className={
              "md:hidden block bg-[#151521] p-1 border border-[#737281] hover:cursor-pointer z-[30000] "
            }
          >
            <AiOutlineMenu
              className={` ${
                showNavItems ? " text-green-main" : " text-white"
              }   text-xl `}
            />
          </div>
        </div>
        {showNavItems && (
          <div className="fixed  inset-0 w-screen h-screen flex flex-col justify-center items-center gap-5 bg-[#151521ea] z-[20000]  ">
            <Link
              to={"/become-creator"}
              onClick={() => setShowNavItems(false)}
              className="bg-green-main text-center p-2 rounded-sm w-1/2"
            >
              CONTENT CREATORS
            </Link>
            <Link
              to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
              target="_blank"
              onClick={() => setShowNavItems(false)}
              className="bg-green-main text-center p-2 rounded-sm w-1/2"
            >
              DOWNLOAD NOW
            </Link>
          </div>
        )}
        <Outlet />
        {/* Footer */}
        <div
          className="bg-[#272639] w-full flex flex-col text-[#ACAAB2]"
          id="footer"
        >
          <div className="w-full flex  flex-col md:flex-row justify-center gap-5 md:gap-24 p-10 md:p-20  md:mb-10">
            <FooterItem
              handleLinkClick={handleLinkClick}
              links={creatorLinks}
              title="creators"
            />
            <FooterItem
              handleLinkClick={handleLinkClick}
              links={aboutLiks}
              title="about"
            />
            <FooterItem
              handleLinkClick={handleLinkClick}
              links={contactusLinks}
              title="contact"
            />
            <FooterItem
              handleLinkClick={handleLinkClick}
              links={privacyLinks}
              title="Privacy"
            />
          </div>
          <div className="text-center p-10  md:border-t border-[#ACAAB2] m-auto w-3/5">
            Copyright © 2023 On The Hash.All Rights Reserved.
          </div>
        </div>
        {showPrivacyPolicy && (
          <div className="w-full fixed inset-0 flex flex-col gap-2 items-center justify-center bg-slate-50 bg-opacity-50 h-screen">
            <div className="w-11/12 p-8  rounded-md sm:w-2/3 h-4/5 bg-white relative overflow-y-auto">
              <PrivacyPolicy />
            </div>
            <button
              className=" text-xl bg-white rounded p-1 hover:cursor-pointer  "
              onClick={() => setShowPrivacyPolicy(false)}
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Navbar;
