import React from "react";
import { MdLocationOn } from "react-icons/md";
import { SubmitHandler, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
};
const Support = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/admin/contact-us`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const getResponse: any = await response.json();
      toast.success(getResponse?.message as string);
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div className=" w-full  mb-12 flex flex-col">
      <ToastContainer  />
      <div className=" pt-12  md:py-12 md:mb-6 text-white">
        <h1 className="text-2xl md:text-5xl text-center px-4  font-extrabold ">
          Get The Support You Need
        </h1>
        <h3 className="text-center mt-2">
          Give us a few details and we’ll help you with the best solution
        </h3>
      </div>
      {/* contact us form starts */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" w-11/12 md:w-2/3 m-auto flex flex-col gap-10 mt-16 "
      >
        <div className="w-full flex-col md:flex-row flex gap-2 md:items-end">
          <div className="flex flex-col flex-1 ">
            <label className=" md:text-xl font-bold text-[#D3D3D7]">
              YOUR NAME
            </label>
            <input
              type="text"
              placeholder="First Name"
              className={`w-full bg-transparent outline-none md:p-5 md:pl-0 p-2 pt-4 pl-0 placeholder-[#ACAAB2] md:text-xl border-b ${
                errors.firstName ? " border-b-red-500" : " border-b-[#D3D3D7]"
              }  text-white`}
              {...register("firstName", { required: true })}
            />
          </div>
          <div className="flex-1">
            <input
              {...register("lastName", { required: true })}
              type="text"
              placeholder="Last Name"
              className={`w-full bg-transparent outline-none  md:p-5 md:pl-0 p-2 pt-4  pl-0 placeholder-[#ACAAB2] md:text-xl border-b ${
                errors.lastName ? " border-b-red-500" : " border-b-[#D3D3D7]"
              }  text-white`}
            />
          </div>
        </div>
        <div className="w-full flex-col md:flex-row flex gap-2 md:items-end">
          <div className="flex flex-col flex-1 ">
            <label className=" md:text-xl font-bold text-[#D3D3D7]">
              EMAIL ADDRESS
            </label>
            <input
              {...register("email", { required: true })}
              type="email"
              placeholder="john@email.com"
              className={`w-full bg-transparent outline-none  md:p-5 md:pl-0 p-2 pt-4 pl-0 placeholder-[#ACAAB2] md:text-xl border-b mb-5 md:mb-0 ${
                errors.email ? " border-b-red-500" : " border-b-[#D3D3D7]"
              }  text-white`}
            />
          </div>
          <div className="flex-1">
            <label className=" md:text-xl font-bold text-[#D3D3D7]">
              PHONE NUMBER
            </label>
            <input
              {...register("phone", { required: true })}
              type="text"
              placeholder="+1 300 400 5000"
              className={`w-full bg-transparent outline-none md:p-5 md:pl-0 p-2 pt-4 pl-0 placeholder-[#ACAAB2] md:text-xl border-b ${
                errors.phone ? " border-b-red-500" : " border-b-[#D3D3D7]"
              }  text-white`}
            />
          </div>
        </div>
        <div className="w-full flex-col md:flex-row flex gap-2 md:items-end">
          <div className="flex flex-col flex-1 ">
            <label className=" md:text-xl font-bold text-[#D3D3D7]">
              SUBJECT
            </label>
            <input
              {...register("subject", { required: true })}
              type="text"
              placeholder="Write Your Subject"
              className={`w-full bg-transparent outline-none  md:p-5 md:pl-0 p-2 pt-4 pl-0 placeholder-[#ACAAB2] md:text-xl border-b ${
                errors.subject ? " border-b-red-500" : " border-b-[#D3D3D7]"
              }  text-white`}
            />
          </div>
        </div>
        <div className="w-full flex-col md:flex-row flex gap-2 md:items-end">
          <div className="flex flex-col flex-1 ">
            <label className=" md:text-xl font-bold text-[#D3D3D7]">
              I HAVE A QUESTION REGARDING
            </label>
            <input
              {...register("message", { required: true })}
              type="text"
              placeholder="Type Your Message"
              className={`w-full bg-transparent outline-none  md:p-5 md:pl-0 p-2 pt-4 pl-0 placeholder-[#ACAAB2] md:text-xl border-b ${
                errors.message ? " border-b-red-500" : " border-b-[#D3D3D7]"
              }  text-white`}
            />
          </div>
        </div>
        <button className="bg-green-main rounded m-auto py-2 mt-10 md:mt-20 px-8 ">
          SEND MESSAGE
        </button>
      </form>
      {/* contact us form ends */}
      <div className="md:w-1/3 w-4/5 m-auto mt-20 md:mb-52">
        <MdLocationOn className=" text-green-main m-auto text-3xl mb-4 " />
        <p className="md:text-2xl text-[#E6E4ED] text-center ">
          ON THE HASH, LLC
        </p>
        <p className="md:text-2xl text-[#ACAAB0] text-center mt-4 ">
          14301 FNB Pkwy, Suite 100, Omaha, NE 68154
        </p>
      </div>
    </div>
  );
};

export default Support;
