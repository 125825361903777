import React from "react";
import { Link, useLocation } from "react-router-dom";

const Section = ({
  image,
  mobileImage,
  title,
  desc,
  edge,
  classes,
  hideButton,
}: {
  image: string;
  title: string;
  mobileImage?: string;
  desc: string;
  edge?: "left" | "right";
  classes?: string;
  hideButton?: boolean;
}) => {
  const { pathname } = useLocation();
  return (
    <div
      className={` ${
        edge === "right"
          ? "md:justify-end md:self-end justify-center items-center"
          : "md:justify-start justify-center items-center"
      } flex  flex-col md:flex-row  mb-16   `}
    >
      <div
        className={`w-4/5 md:w-[54.5%]  ${
          edge === "right" && "md:order-2"
        } relative  `}
      >
        <img
          className={`w-full z-10 relative hidden md:block ${classes}   `}
          src={image}
          alt=""
        />
        <img
          className={`w-full z-10 md:hidden relative ${classes}   `}
          src={mobileImage!}
          alt=""
        />
        <img
          src="./assets/OTH-Index/fog-section-background.png"
          alt=""
          className="absolute w-fulln top-0 z-[1] hidden md:block  "
        />
      </div>

      <div
        className={`flex justify-center 2xl:w-[20%] w-11/12 md:w-1/3 flex-col font-bold text-white text-3xl ${classes!} `}
      >
        <p className="text-center md:text-left">{title}</p>
        <p className="my-2 text-center md:text-left text-base font-light  ">
          {desc}{" "}
        </p>
        {hideButton ? null : (
          <Link
            to="https://admin.onthehash.com"
            target="_"
            className="bg-green-main text-center m-auto md:m-0 py-3  font-normal text-black text-sm   rounded-md 2xl:w-[75%] w-2/3"
          >
            BECOME A CONTENT CREATOR
          </Link>
        )}
      </div>
    </div>
  );
};

export default Section;
