import React from "react";
import Section from "../components/Section";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="w-full  mb-12 flex flex-col">
      <div className="py-12 mb-6 text-white">
        <h1 className="text-5xl text-center px-4  font-extrabold ">About Us</h1>
        <h3 className="text-center mt-2 p-2">
          Developing the next generation football mobile gaming app
        </h3>
      </div>
      <Section
        image="./assets/OTH-About us/What-We-Do.png"
        mobileImage="./assets/OTH-About us/What-We-Do-full-img.png"
        desc="On The Hash is a football gaming platform. We are developing a gaming platform that will allow fans to learn American football from the very basics to complex formations. You also will be able to learn the very plays, terminology and strategies of your favorite teams and compete with others in evaluating film and making the correct call. You Make The Call tests your knowledge to identify the defense and select the appropriate play. Need a little film room time? On The Hash allows you to go into in-depth analysis of previous games with an expert and get you ready for next week’s game."
        title="What We Do"
        classes="w-1/3  "
        hideButton={true}
      />

      <div className=" w-full md:w-2/3 m-auto md:mt-32 text-white flex flex-col justify-center z-50 ">
        <h1 className="2xl:text-5xl text-center md:text-left text-4xl 2xl:pb-12 pb-8 border-b border-b-[#ACAAB2] ">
          Who We Are
        </h1>
        {/* DAVID MUSSMAN */}
        <div className="w-full justify-center items-center flex flex-col md:flex-row md:mt-20 mt-10">
          <img
            className="md:w-1/3 w-11/12"
            src="./assets/OTH-About us/David-Mussman-who-we-are-section.png"
            alt=""
          />
          <div className="p-8">
            <p className="text-3xl mb-4 text-white font-bold ">DAVID MUSSMAN</p>
            <p className="text-lg  text-green-main">TECHNOLOGY DEVELOPER,</p>
            <p className="text-lg  text-green-main">
              COMPLEX TRANSACTIONS, GLOBAL PERSPECTIVE
            </p>
            <p className="text-xl mt-5  text-[#ACAAB2]">
              Executive Leader | Business Partner | Corporate Lawyer | Board
              Advisor
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 mt-[60px] p-3 pl-5 md:p-0">
          <p className="text-3xl">PERSONAL BIO</p>
          <p className="text-[#ACAAB2] text-xl ">
            <span className="text-[#E6E4ED] text-xl">
              David Mussman is a practical, hands-on, business-focused executive{" "}
            </span>
            who led IP Management for a Fortune 1,000 company. Dave created a
            patent program that resulted in the development and acquisition of
            over 1,100 patents and patent applications. Of these, he monetized
            over 250 totaling $7.6 million and was named inventor on 17 patents.
            Throughout his career, he has been a critical advisor to the Board
            of Directors and various corporate committees in areas key to
            multi-million-dollar international business development, including
            risk management, litigation oversight, M&A activity, compliance and
            complex debt and equity structure.
          </p>
          <p className="text-[#ACAAB2] text-xl ">
            <span className="text-[#E6E4ED] text-xl">
              Dave spent 18 years navigating roles as Consultant, Executive Vice
              President, General Counsel, Corporate Secretary and Vice President
              for West Corporation
            </span>
            – a publicly-traded global technology company providing voice and
            data services to telecommunications, retail, financial services,
            public safety, technology and healthcare industries.
          </p>
          <p className="text-[#ACAAB2] text-xl ">
            <span className="text-[#E6E4ED] text-xl">
              On the front line of making the company’s most vital judgement
              calls,{" "}
            </span>
            Dave facilitated and managed all legal and business aspects of
            complex securities and financing transactions, M&As, divestitures,
            JVs and following integrations. This includes management of over 40
            acquisitions totaling more than $3 billion in purchases, negotiation
            and completion of a $275 million divestiture, legal oversight of
            go-public and go-private transactions, management of secondary
            offerings, and sale of West Corporation to Apollo Global Management
            valued at over $5 billion.
          </p>
          <p className="text-[#ACAAB2] text-xl ">
            <span className="text-[#E6E4ED] text-xl">
              Dave holds a Juris Doctor degree{" "}
            </span>
            from the University of Nebraska College of Law and is a practicing
            member of the State Bar of Nebraska. He earned his Bachelor’s degree
            in Psychology, English and Business from the University of Nebraska.
            An entrusted business and legal advisor, Dave was Chairman for
            Immanuel Communities Board of Directors and key member of the
            finance, governance, healthcare, identity and executive committees.
          </p>
        </div>
        {/* TYLER-HUCK */}
        <div className="w-full flex flex-col  md:flex-row mt-20">
          <img
            className=" w-11/12 md:w-1/3 justify-center m-auto md:m-0"
            src="./assets/OTH-About us/TYLER-HUCK-who-we-are-section.png"
            alt=""
          />
          <div className="p-8">
            <p className="text-3xl mb-4 text-white font-bold ">TYLER HUCK</p>
            <p className="text-lg  text-green-main">
              LONGTIME SALESMAN, ATHLETE
            </p>
            <p className="text-lg  text-green-main">COAND TRAINER</p>
            <p className="text-xl mt-5  text-[#ACAAB2]">
              Marketing | Salesman | Investor
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col gap-5 mt-[60px] md:p-0 p-5">
          <p className="text-3xl">PERSONAL BIO</p>
          <p className="text-[#ACAAB2] text-xl ">
            Tyler was born and raised in Ocean City, NJ, where his passion for
            the outdoors sprang up naturally. From childhood through high
            school, he thrived on athletic competition in multiple sports. Tyler
            earned his bachelor’s degree in marketing at St. Joseph’s University
            in Philadelphia, PA as a scholarship athlete for their Division I
            rowing team. Upon graduation in 2012, Tyler channeled his
            competitive nature, along with his education and passion for
            sales/marketing, to sell real estate in the South Jersey coastal
            region. He is still actively involved in his real estate investments
            in Northern Florida as well.
          </p>
          <p className="text-[#ACAAB2] text-xl ">
            Despite a high level of success, Tyler realized he was missing an
            element of satisfaction in his career. This led to a search for a
            role where his skills and background could be combined with his
            lifelong passion for football. Upon finding On The Hash, he reached
            out to Dave, who was in pursuit of someone to take his gaming app
            creation to the next level. Within a couple days, Tyler was on a
            flight to Omaha, Nebraska, to begin putting On The Hash on the map
            with NCAA and NFL teams and their fans across the country and the
            globe.{" "}
          </p>
        </div>
      </div>
      <div className="w-2/3 m-auto mb-10 md:mb-52 flex md:flex-row flex-col justify-center gap-5 mt-10  md:mt-48">
        <Link
          to="/become-creator"
          className="bg-green-main text-center  rounded-md 2xl:text-2xl text-lg 2xl:w-[418px] w-72 p-4"
        >
          BECOME A CONTENT CREATOR
        </Link>
        <Link
          to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
          target="_blank"
          className="text-green-main flex justify-center items-center rounded-md 2xl:text-2xl text-center text-lg 2xl:w-[418px] w-72 border p-4 border-green-main"
        >
          DOWNLOAD THE APP
        </Link>
      </div>
    </div>
  );
};

export default AboutUs;
