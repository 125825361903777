import React from "react";
import Section from "../components/Section";
import { Link } from "react-router-dom";

const Careers = () => {
  return (
    // <div className="w-full  mb-12 flex flex-col">
    //   <div className="py-12 mb-6 text-white flex flex-col">
    //     <h1 className="2xl:text-5xl text-4xl text-center px-4  font-extrabold ">
    //       The Best Place To Kick Off
    //     </h1>
    //     <h1 className="2xl:text-5xl text-4xl text-center px-4  font-extrabold ">
    //       Your Career
    //     </h1>
    //     <button className="bg-green-main text-black p-2 m-auto mt-6 2xl:mt-10    rounded-sm">
    //       SEARCH JOBS
    //     </button>
    //   </div>
    //   <div className="w-full m-auto">
    //     <img
    //       src="./assets/OTH-Careers_Search Job/banner-image.png"
    //       alt=""
    //       className="w-1/2 m-auto"
    //     />
    //   </div>
    //   <div className="w-4/5 2xl:w-2/3 flex justify-center gap-5 2xl:gap-0 m-auto mt-8 mb-12 2xl:mb-16 ">
    //     <div className="flex flex-col justify-center text-white items-start w-1/2 gap-4">
    //       <p className="text-[#E6E4ED] text-3xl font-bold">You’re Up Next</p>
    //       <p className="text-[#ACAAB2] w-2/3">
    //         We’re growing fast and hiring the best, and we’d love to find a
    //         place for you.
    //       </p>
    //       <p className="text-[#ACAAB2] w-2/3">
    //         We put people first — with the tools, support and trust they need to
    //         do their best, explore new ideas and take big swings.
    //       </p>
    //       <Link
    //         to="/openings"
    //         className="bg-green-main p-2 px-4 rounded-sm text-black"
    //       >
    //         VIEW OPEN POSITIONS
    //       </Link>
    //     </div>
    //     <img
    //       src="./assets/OTH-Careers_Search Job/Youre-Up-Next (2).png"
    //       alt=""
    //       className="2xl:w-[600px] w-1/3"
    //     />
    //   </div>
    // </div>
    <div className="flex justify-center text-3xl items-center md:p-20 text-white font-extrabold md:text-5xl my-36 md:mt-36 md:m-0 h-[40vh] md:h-auto ">
      Coming Soon
    </div>
  );
};

export default Careers;
