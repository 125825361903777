import React from "react";
import Section from "../components/Section";
import { Link } from "react-router-dom";
const BecomeACreator = () => {
  return (
    <div className=" w-full  mb-12 flex flex-col">
      <div className="py-12 mb-6 text-white">
        <h1 className=" text-4xl md:text-5xl text-center px-4  font-extrabold ">
          Become Content Creator
        </h1>
        <h3 className="text-center mt-2">
          Are you a YouTuber,coach or athlete that can help people know your
          team and your skills?
        </h3>
      </div>
      <Section
        image="./assets/OTH-Become Content Creator/Create-Unique-Quizzes.png"
        mobileImage="./assets/OTH-Become Content Creator/Create-Unique-Quizzes-full-img.png"
        desc="Our online tool allows you to showcase your analysis and knowledge."
        title="Create Unique Quizzes With Our Content Creator Tool"
        edge="left"
      />
      <Section
        image="./assets/OTH-Become Content Creator/Unlock-the-Power-of-personalized-quizzing.png"
        mobileImage="./assets/OTH-Become Content Creator/Unlock-the-Power-of-personalized-quizzing-full-img.png"
        desc=""
        title="Save your own creative questions to speed uploads and show your unique analysis."
        edge="right"
      />
      <Section
        image="./assets/OTH-Become Content Creator/Create-Perfectly-Crafted.png"
        mobileImage="./assets/OTH-Become Content Creator/Create-Perfectly-Crafted-full-image.png"
        desc="The video window allows you to select the exact spot in the play or your presentation when you want the user to get a quiz."
        title="Create Perfectly Crafted Questions Every Time And at the Precise Time"
        edge="left"
      />
      <Section
        image="./assets/OTH-Become Content Creator/Custom-Dashboard-for-Data-that-Matters.png"
        mobileImage="./assets/OTH-Become Content Creator/Custom-Dashboard-for-Data-that-Matters-full-img.png"
        desc="See all of your quizzes, when they were created and how many users took the quiz."
        title="Custom Dashboard for Data that Matters"
        edge="right"
      />
      <Section
        image="./assets/OTH-Become Content Creator/Create-Perfectly-Crafted.png"
        mobileImage="./assets/OTH-Become Content Creator/Create-Perfectly-Crafted-full-image.png"
        desc="The video window allows you to select the exact spot in the play or your presentation when you want the user to get a quiz."
        title="Create Perfectly Crafted Questions Every Time And at the Precise Time"
        edge="left"
      />
      <Link
        to={"https://admin.onthehash.com"}
        target="_"
        className="border-green-main text-green-main border rounded p-4 m-auto hover:cursor-pointer hover:bg-green-main hover:text-black z-50"
      >
        BE A CREATOR
      </Link>
    </div>
  );
};

export default BecomeACreator;
