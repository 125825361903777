import React, { useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
type FooterProps = {
  title: string;
  handleLinkClick: (privacyDialogue?: boolean) => void;
  links: { link: string; label: string }[];
};
const FooterItem = (props: FooterProps) => {
  const { title, links, handleLinkClick } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const ToggleDropdown = () => {
    setIsExpanded((p) => !p);
  };

  return (
    <div
      className={`flex flex-col md:border-none overflow-hidden  ${
        isExpanded ? " max-h-40" : "max-h-8  md:max-h-40"
      } md:h-auto pb-4 md:pb-0 border-b-2 border-[#ACAAB2] transition-all`}
    >
      <p
        className="font-bold mb-2 md:mb-0  text-white flex items-center justify-between "
        onClick={() => ToggleDropdown()}
      >
        {title.toUpperCase()}
        <AiOutlineRight
          className={`md:hidden ${isExpanded && " rotate-90 "} transition-all `}
        />
      </p>
      {links.map((l) => {
        return (
          <Link
            className="w-1/2 md:w-auto"
            to={l.link}
            onClick={() => handleLinkClick(l.label === "Privacy Policy")}
            target={l.label === "Creator Login" ? "_blank" : "_self"}
          >
            {l.label}
          </Link>
        );
      })}
    </div>
  );
};

export default FooterItem;
