import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="h-4/5">
      <p>
        <strong>Privacy Statement</strong>
      </p>
      <p>Effective September 28, 2023</p>
      <p>
        This Privacy Statement applies to On the Hash, LLC (“Company” “we” “us”
        “our”) application and website. The Company values the privacy of our
        customers, users, and individuals who access or use our application,
        website, products, or services (collectively, the “Services”). Your
        privacy is an important factor we consider in the development of our
        Services. This Privacy Statement explains generally how we receive
        Personal Information about you, and what we do with that Personal
        Information once we have it.
      </p>
      <p>
        <strong>
          <em>What do we mean by “Personal Information”?</em>
        </strong>
      </p>
      <p>
        “Personal Information” means information or data that relates to an
        identified or identifiable individual. “Sensitive Personal Information”
        is a subset of Personal Information and is data about children,
        financial information, health information (including PHI as defined by
        the Health Insurance Portability and Accountability Act), Social
        Security or other national identification number, racial or ethnic
        origin, political opinions, religious beliefs, trade union membership,
        sex life, criminal convictions, and precise geolocation data.
      </p>
      <p>
        <strong>
          <em>What type of Personal Information is collected?</em>
        </strong>
      </p>
      <p>
        We may collect data, including Personal Information, about you as you
        use our Services and interact with us. This information may include
        name, email address, credit card information including credit card
        number, billing information and history, IP address, phone number, login
        information (username and password), marketing preferences, or social
        media identification. If we link other data with your Personal
        Information, we will treat that linked data as Personal Information. We
        also collect Personal Information from third-party sources and engage
        third parties to collect Personal Information to assist us.
      </p>
      <p>
        Please understand, in addition to collecting Personal Information, we
        may also gather information that does not personally identify you
        regarding your use of our app or our website (“Anonymous Information”).
        We may use the Anonymous Information we collect regarding your use of
        our app or website to measure the effectiveness of our marketing
        efforts, for improving the Services we offer to you, or to improve our
        app or our website. Generally, you will not be aware when we collect
        such Anonymous Information. It may be collected in various ways, such as
        through traffic data or direct surveys of our users, our vendors, our
        advertisers or our customers and may entail the use of, among other
        things, cookies, IP addresses, or other numeric codes used to identify
        the computer or other device used to access our website.
      </p>
      <p>
        <strong>
          <em>How do we receive Personal Information about you?</em>
        </strong>
      </p>
      <p>We learn Personal Information about you when:</p>
      <ul>
        <li>
          you give it to us directly (e.g., when you choose to contact us, or
          register on our application or website);
        </li>
        <li>
          we collect it automatically through our Services (e.g., your use of
          the Services);
        </li>
        <li>
          when we try to understand more about you based on Personal Information
          you’ve given to us (e.g., when we use your IP address to customize
          language for some of our Services).
        </li>
      </ul>
      <p>
        By providing your Personal Information, you opt-in and consent to its
        collection, use, disclosure, sharing, and transfer as described in this
        Privacy Statement.
      </p>
      <p>
        <strong>
          <em>What do we do with your Personal Information once we have it?</em>
        </strong>
      </p>
      <p>
        <strong>On the Hash does NOT sell your personal information.</strong>
      </p>
      <p>
        When you give us Personal Information, we will use it in the ways for
        which you’ve given us permission. Generally, we use your Personal
        Information to help us provide and improve our Services for you. We may
        also use the Personal Information you provide to contact you regarding
        additional or new services and features we offer, changes to our app or
        website including but not limited to adding additional content, or
        important information regarding us.
      </p>
      <p>
        We may use the Personal Information to enforce our agreements with you,
        prevent fraud and other prohibited or illegal activities, for other
        legally permissible purposes, and to ensure we comply with applicable
        laws and prevent or detect use or abuse of our Services.
      </p>
      <p>
        <strong>
          <em>How long will we retain Personal Information?</em>
        </strong>
      </p>
      <p>
        We will retain your Personal Information only as long as needed to
        fulfill the purposes for which it was collected or as required by law.
        Your information will be deleted, anonymized or pseudonymized once it is
        no longer needed to comply with our business requirements, legal
        obligations, resolve disputes, protect our assets, or enforce our
        agreements.
      </p>
      <p>
        <strong>
          <em>When do we share your Personal Information with others?</em>
        </strong>
      </p>
      <p>We will/may share your Personal Information with others:</p>
      <ul>
        <li>When we have asked and received your permission to share it.</li>
        <li>
          For processing or providing services to you. We may use others to help
          us provide our Services (e.g., maintenance, analysis, audit, payments,
          fraud detection, marketing and development).
        </li>
        <li>
          To third parties who may market their products and services to you and
          in which we may obtain a fee based upon the marketing or the sale of
          their products or services.
        </li>
        <li>
          To provide aggregate Personal Information to our business partners to
          make our Services better..
        </li>
        <li>
          To follow the law whenever we receive requests about you from a
          government entity or related to a lawsuit. We’ll notify you when we’re
          asked to hand over your Personal Information in this way unless we’re
          legally prohibited from doing so. When we receive requests like this,
          we’ll only release your Personal Information if we have a good faith
          belief the law requires us to do so. Nothing in this Statement is
          intended to limit any legal defenses or objections you may have to a
          third party’s request to disclose your Personal Information.
        </li>
        <li>
          If we have a good faith belief it is reasonably necessary to protect
          the rights, property or safety of you, our other users, On the Hash,
          or the public.
        </li>
        <li>
          If our organizational structure or status changes (e.g., if we undergo
          a restructuring, are acquired, enter in to partnership agreements or
          go bankrupt), we may pass your Personal Information to a successor or
          affiliate.
        </li>
      </ul>
      <p>
        <strong>
          <em>How do we protect your Personal Information?</em>
        </strong>
      </p>
      <p>
        We are committed to protecting your Personal Information once we have
        it. We implement physical, administrative and technical security
        measures. If, despite these efforts, we learn of a security breach
        involving your Personal Information, when required by law or contractual
        obligations, we’ll notify you so appropriate protective steps can be
        taken. On the Hash is not responsible for unauthorized access to such
        Personal Information by hackers or others that obtain access through
        illegal measures in the absence of negligence on the part of On the
        Hash.
      </p>
      <p>
        You may have access to other sites through the On the Hash website or
        our app. These sites may have different security practices and you
        should familiarize yourself with those practices.
      </p>
      <p>
        <strong>
          <em>How can you protect your Personal Information?</em>
        </strong>
      </p>
      <p>
        Electronic communication (e.g., email, online chat or instant messaging,
        etc.) you send to us may not be secure unless we advise you in advance
        security measures will be in place prior to you transmitting the
        information. For this reason, we ask you do not send Personal
        Information such as financial information, social security numbers or
        passwords to us through unsecured electronic communication. Users should
        also take care with how they handle and disclose their Personal
        Information. Please refer to the Federal Trade Commission’s Web site at 
        <a
          href="http://www.ftc.gov/bcp/menus/consumer/data.shtm%20"
          target="_blank"
        >
          <em>http://www.ftc.gov/bcp/menus/consumer/data.shtm</em>
        </a>
         for information about how to protect yourself against identity theft.
      </p>
      <p>
        The On the Hash app and website may contain hyperlinks that can take you
        to websites run by third parties (“Third-Party Websites”). Any hypertext
        or other links to Third-Party Websites from the On the Hash app or its
        website are provided solely as a convenience to you. If you use these
        links, you will leave the On the Hash app or website. On the Hash has
        not reviewed all of these Third-Party Websites and does not control and
        is not responsible for any of these websites or their content or
        practices. Thus, On the Hash does not endorse or make any
        representations about them, or any information, software, or other
        products or materials found there, or any results that may be obtained
        from using them. If you decide to access any of the Third-Party Websites
        linked to the On the Hash app or website, you do this entirely at your
        own risk. Remember, this Privacy Statement only applies to On the Hash.
        When you are no longer on the On the Hash app or its website, you may
        encounter different privacy and security practices and you should
        familiarize yourself with those practices each time you visit a new
        website.
      </p>
      <p>
        Please be aware any information you choose to share on any publicly
        available portion of the Services or with third parties, including
        without limitation your personal page, chat messages, forum posts,
        blogs, or any other information you provide may be collected and used by
        third parties without restriction.
      </p>
      <p>
        <strong>
          <em>What about cookies and other tracking technology?</em>
        </strong>
      </p>
      <p>
        A cookie is a small file, typically of letters and numbers, downloaded
        onto a device when the user accesses certain websites. Cookies can make
        the web or our On The Hash app more useful by storing information about
        your preferences for a particular website or a service. Cookies in and
        of themselves do not personally identify users, although they do
        identify a user’s computer.
      </p>
      <p>
        Cookies are typically classified as either session Cookies or persistent
        Cookies depending on whether they expire at the end of a browser session
        (from when a user opens the browser window to when they exit the
        browser) or they can be stored for longer.
      </p>
      <ul>
        <li>
          Session Cookies – allow websites to link the actions of a user during
          a browser session. They may be used for a variety of purposes such as
          remembering what a user has put in their shopping basket as they
          browse around a site. They could also be used for security when a user
          is accessing internet banking or to facilitate use of webmail. These
          session Cookies expire after a browser session, so would not be stored
          longer term. For this reason, session Cookies may sometimes be
          considered less privacy intrusive than persistent Cookies.
        </li>
        <li>
          Persistent Cookies – are stored on a user’s device in between browser
          sessions which allows the preferences or actions of the user across a
          site (or in some cases across different websites) to be remembered.
          Persistent Cookies may be used for a variety of purposes including
          remembering users’ preferences and choices when using a site or to
          target advertising.
        </li>
      </ul>
      <p>
        On the Hash uses cookies or other similar tracking technologies to
        provide you with better Services.
      </p>
      <p>
        Most browsers are initially set to accept Cookies. You may configure
        your browser to accept all Cookies, reject all Cookies, or notify you
        when a Cookie is set. You can manage your own Cookies preferences by
        using your browser settings: each browser is different, so check the
        “Help” menu of your browser to learn how to change your Cookie
        preferences or delete them. If you prefer, you can set your browser to
        refuse Cookies. You block Cookies by activating the setting on your
        browser which allows you to refuse the setting of all or some Cookies.
      </p>
      <p>
        Please note you may browse the On the Hash website without accepting
        Cookies, however, you may not be able to take full advantage of the
        functionality of the website or the Services if you do so. Other
        Services, particularly those which require a login and password, require
        Cookies and cannot be used when you have disabled Cookies in your
        browser. For more general information, you can visit
        www.allaboutcookies.org.
      </p>
      <p> </p>
      <p>
        <strong>
          <em>How do we ensure compliance with legal obligations?</em>
        </strong>
      </p>
      <p>
        In connection with the Services, and if applicable, you and On the Hash
        shall at all times ensure compliance with any privacy and data
        protection laws around the world. By giving us Personal Information, we
        may conduct transfers of your Personal Information as necessary to
        perform the Services. No matter what country your Personal Information
        is in, we comply with applicable law and will also abide by the
        commitments we make in this Privacy Statement. We do not knowingly
        collect Personal Information from children under 13. Our Services are
        not intended for use by those under the age of 13.
      </p>
      <p>
        <strong>
          <em>What choices do you have regarding your Personal Information?</em>
        </strong>
      </p>
      <p>
        We are aware you have several rights in respect to how we process your
        Personal Information, which include but are not limited to, a right to
        access your Personal Information, be forgotten, restrict and/or object
        to processing in some circumstances and request your Personal
        Information be transferred to you or your nominated third party.
      </p>
      <p>
        You may choose not to have your information disclosed to a third party
        (unless we are legally required to share it).  You may also choose to
        not have your information used for a purpose materially different than
        the purpose for which it was originally collected or subsequently
        authorized by you.
      </p>
      <p>
        We will take reasonable steps to give you the opportunity to correct
        inaccuracies in the Personal Information we retain concerning you and
        delete Personal Information concerning you upon your request, unless the
        burden or expense of providing access would be disproportionate to the
        risks to your privacy or where the rights of persons other than you
        would be violated.  We may deny a request to delete your data if we have
        legal obligations to retain it.  Any such data will be subject to our
        standard retention guidelines.
      </p>
      <p>
        You are entitled to know whether we hold personal data about you and, if
        we do, to have access to that personal data and require it to be
        corrected if it is inaccurate. In some circumstances, you may have the
        right to oppose the use or disclosure of your data or have your data
        deleted. You can do this by contacting us as directed in the “Contact
        Us” section below.
      </p>
      <p>
        To correct inaccuracies in your Personal Information, limit purpose of
        use and disclosure, delete your Personal Information, or for any other
        requests or questions, please contact us as directed in the “Contact Us”
        section below. Once we have verified your identity, we will complete the
        request from you or an authorized agent you have designated.  We will
        make all efforts to verify your identity using information we already
        have but may need to request additional data if we are unable to make
        the verification on this data alone.  If you designate an agent to make
        requests for you, we reserve the right to ask for proof of the agency.
      </p>
      <p>
        On the Hash will not discriminate against any individual for exercising
        their privacy rights outlined above. This includes, but is not limited
        to, denying services, changes in pricing, or lower quality goods or
        services.
      </p>
      <p>
        <strong>
          <em>What if we change this Privacy Statement?</em>
        </strong>
      </p>
      <p>
        We may need to change this Statement and our notices. Unless otherwise
        required, the updates will be posted online. Unless further steps are
        necessary related to the changes, your continued use of the Services
        after the effective date of such changes constitutes your acceptance of
        such changes. To make your review more convenient, we will post an
        effective date at the top of the page.
      </p>
      <p>
        <strong>
          <em>How to Contact Us?</em>
        </strong>
      </p>
      <p>
        If you want to make a correction to your Personal Information, or you
        have any questions about our privacy statement, please contact us at:
      </p>
      <p>
        <a href="mailto:privacy@onthehash.com" className="text-blue-500">
          privacy@onthehash.com
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
