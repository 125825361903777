import React, { useState } from "react";
import Card from "../components/Card";
import { cardData } from "../utils/data";
import { FaMobileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const LandingPage = () => {
  return (
    <div className="w-full">
      <div className="section-1 sm:flex sm:flex-row gap-[2rem] sm:justify-center flex-wrap mt-8 flex-col items-center  ">
        <div className="sm:w-[30rem] sm:pt-[4rem] mx-5 pt-[2rem] flex flex-col justify-center    ">
          <h2 className="sm:text-5xl text-4xl text-[#E6E4ED] text-center sm:text-left">
            A Football Coach
            <br />
            In Your Pocket
          </h2>
          <p className="text-sm sm:w-72 pt-1 text-center sm:text-left text-[#E6E4ED]">
            On The Hash is a football game platform. Our Content providers test
            the fan on their knowledge and allow fans to learn football from the
            very basics to complex coaching concepts.
          </p>
          <Link
            to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
            target="_blank"
            className="hidden sm:block"
          >
            <div className="text-sm  hover:bg-green-main hover:text-black w-[11rem] flex items-center text-green-main border-green-main border rounded p-1 mt-2">
              <FaMobileAlt />
              <span className="pl-2">DOWNLOAD THE APP</span>
            </div>
          </Link>
        </div>
        <div className="sm:w-[25rem] mx-auto  sm:m-0 w-[18rem] ">
          <img
            className="h-[25rem] object-contain hidden sm:block  "
            src="./assets/OTH-Index/banner-img-A-Football-Coach.png"
            alt=""
          />
          <img
            className="h-[25rem] object-contain sm:hidden  "
            src="./assets/OTH-Index-Mobile Version/banner-image.png"
            alt=""
          />
        </div>
        <Link
          to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
          target="_blank"
          className="sm:hidden w-[11rem]  text-sm flex items-center mx-auto text-green-main border-green-main border rounded p-1 "
        >
          <FaMobileAlt />
          <span className="pl-2 ">DOWNLOAD THE APP</span>
        </Link>
      </div>
      <div className="section-2   flex justify-center items-center sm:p-9 px-2 pt-5">
        <div className="hidden  w-[70rem] gap-5 sm:flex flex-wrap justify-around items-center sm:w-[90%]">
          {cardData.map((data, index) => (
            <Card
              key={index}
              heading={data.heading}
              image={data.image}
              para={data.para}
            />
          ))}
        </div>
        <div className=" w-full flex gap-2 flex-wrap  items-center sm:w-[90%] sm:hidden">
          <BsArrowLeftCircleFill
            className="sm:hidden btn-prev-slide"
            color="white"
            size={"1rem"}
          />
          <Swiper
            className="sm:hidden  w-[76%] mx-2"
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            navigation={{
              nextEl: ".btn-next-slide",
              prevEl: ".btn-prev-slide",
            }}
            modules={[Navigation]}
          >
            {cardData.map((data, index) => (
              <SwiperSlide key={index}>
                <Card
                  heading={data.heading}
                  image={data.image}
                  para={data.para}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <BsArrowRightCircleFill
            className="sm:hidden btn-next-slide"
            color="white"
            size={"1rem"}
          />
        </div>
      </div>

      <div className="section-3 flex flex-col justify-center  p-[2rem]  ">
        <div className="flex  flex-col justify-center items-center  ">
          <h2 className="text-[#E6E4ED] sm:w-[42rem] text-3xl sm:text-4xl w-[1/2] text-center">
            Professional Football Knowledge and Secrets Now Available To All
          </h2>
          <p className=" text-[#ACAAB2] text-center text-md mt-5 sm:text-lg ">
            Our gaming platform is powered by professional content providers.
            Get quizzed by team coaches, your favorite QB <br /> putting up
            their film and your favorite YouTuber all teaching you through the
            use of quick quizzes.
          </p>
        </div>
        <div className="flex flex-wrap  justify-center mt-[2rem] sm:mt-[5rem] p-8   ">
          <div className="sm:w-1/3  flex flex-col justify-center  ">
            <h2 className="sm:text-4xl text-3xl text-[#E6E4ED] sm:text-left text-center">
              Get Schooled on Football Like Matt Rhule and Prime Time
            </h2>
            <div className="mt-5 sm:mt-0 sm:w-1/3  py-4 sm:ml-[8rem] sm:hidden  ">
              <img
                className="sm:h-[25rem] object-contain  "
                src="./assets/OTH-Index-Mobile Version/Get-Schooled-on-football-like-matt.png"
                alt=""
              />
            </div>
            <p className="text-sm  text-center sm:text-left pt-1 text-[#ACAAB2]">
              We provide an authentic football gaming experience with tools and
              expert guidance that make this complex sport feel accessible. Our
              interactive tutorial will take you through the basics of football,
              from strategy to formations and terminology, allowing you to learn
              all the skills necessary to be a successful football fan.
            </p>
            <Link
              to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
              target="_blank"
              className="mx-auto sm:mt-4 sm:mx-0 text-sm inline-block w-[10rem] bg-green-main text-center rounded p-1 mt-2"
            >
              DOWNLOAD THE APP
            </Link>
          </div>
          <div className="mt-5 sm:mt-0 sm:w-1/3 sm:ml-[8rem] hidden sm:block  ">
            <img
              className="h-[25rem] object-contain  "
              src="./assets/OTH-Index/section-Get-Schooled-on-Football.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-wrap   justify-center  items-center p-8  ">
          <div className="sm:w-1/3 sm:order-2  sm:ml-[8rem]  flex flex-col justify-center   ">
            <h2 className="sm:text-4xl text-3xl text-[#E6E4ED] sm:text-left text-center">
              Compete in Evaluating Plays and Making Calls
            </h2>
            <div className="mt-5 mx-auto sm:mt-0 sm:w-1/3  py-4 sm:ml-[8rem] sm:hidden bg-[url(../public/assets/OTH-Index/fog-section-background.png)]  bg-cover  bg-bottom ">
              <img
                className=" h-[25rem] object-contain "
                src="./assets/OTH-Index/section-compete-in-Evaluating-plays-and-making-calls.png"
                alt=""
              />
            </div>
            <p className="text-sm  text-center sm:text-left pt-1 text-[#ACAAB2]">
              You think you know your team’s schemes? You know the best play for
              third and 6 on the 9 yard line. Your knowledge is scored and
              compared to all other fans.
            </p>
            <Link
              to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
              target="_blank"
              className="mx-auto sm:mt-4 sm:mx-0 text-sm inline-block w-[10rem] bg-green-main text-center rounded p-1 mt-2"
            >
              DOWNLOAD THE APP
            </Link>
          </div>
          <div className="mt-5 sm:mt-0 sm:w-1/3  hidden sm:block   bg-[url(../public/assets/OTH-Index/fog-section-background.png)]  bg-cover  bg-bottom ">
            <img
              className=" h-[25rem] object-contain "
              src="./assets/OTH-Index/section-compete-in-Evaluating-plays-and-making-calls.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex   flex-col gap-8 justify-center items-center mt-[2rem]">
          <h4 className="text-[#E6E4ED] text-center sm:w-[35rem]">
            “We will test your knowledge of the game every week. Each week you
            will receive video clips. Each video will have quiz questions. Some
            are super easy - what down is it? Some are really hard. All quizzes
            are created by our professional content creators.”
          </h4>

          {/* <img
            className=" h-[18rem]   w-[35rem] object-contain   sm:mt-8"
            src="./assets/OTH-Index/section-we-will-test-your-knowledge.png"
            alt=""
          /> */}
          <video
            autoPlay
            muted
            loop
            className="w-[90%] sm:w-2/4 p-0 m-0 rounded-md border-2 border-white "
          >
            <source
              src="/video1.mp4"
              type="video/mp4"
              className="object-contain "
            />
          </video>

          <Link
            to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
            target="_blank"
          >
            <button className="text-sm inline-block w-[10rem]  bg-green-main  rounded p-1 sm:mt-6">
              DOWNLOAD THE APP
            </button>
          </Link>
        </div>
      </div>
      <div className="section-4 p-[2rem]  flex flex-col justify-center items-center">
        <div className="py-[2.5rem] sm:w-[38rem] text-center ">
          <h2 className="text-[#E6E4ED] text-3xl sm:text-4xl">
            Go from Football Novice to Pro With On the Hash
          </h2>
          <p className="text-sm text-[#ACAAB2] pt-2">
            Athletes, coaches, and teams use On the Hash to gain an advantage in
            every major team worldwide. We aim to help them learn more
            efficiently so that they can perform better.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center sm:w-[25rem] sm:justify-between ">
          <h2 className=" p-[2rem] text-center">
            <span className="text-5xl text-green-main">131</span>
            <br />
            <span className="text-[#FFFFFF] p-5 text-xs inline-block">
              TEAMS
            </span>
          </h2>
          <h2 className=" p-[2rem] text-center">
            <span className="text-5xl text-green-main">550+</span>
            <br />
            <span className="text-[#FFFFFF] inline-block p-5 text-xs">
              QUIZZES
            </span>
          </h2>
        </div>
        {/* <div className="hidden sm:flex items-center justify-center flex-wrap gap-5">
          <CoachCard
            image="./assets/OTH-Index/testimonial-Carlos-Cruz.png"
            heading="Carlos Cruz"
            para=" I was able to understand the basics of football, as well as get insight
        into the plays, terminology and strategies of my favorite teams."
            subtitle="Fitness Trainer, Spain National Team"
          />
          <CoachCard
            image="./assets/OTH-Index/testimonial-Roberto-Martinez.png"
            heading="Roberto Martinez"
            para="On The Hash is a great platform for football fans who want to dive deep into the game. Highly recommended!"
            subtitle="Head Coach, Belgian National Team"
          />
        </div>
        <div className=" sm:hidden w-[20rem] gap-1 flex items-center justify-center flex-wrap ">
          <BsArrowLeftCircleFill
            className="sm:hidden prev-slide  "
            color="white"
            size={"1rem"}
          />
          <Swiper
            className="w-[80%] h-[20rem] flex justify-center"
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            navigation={{
              nextEl: ".next-slide",
              prevEl: ".prev-slide",
            }}
            modules={[Navigation]}
          >
            <SwiperSlide>
              <CoachCard
                image="./assets/OTH-Index/testimonial-Carlos-Cruz.png"
                heading="Carlos Cruz"
                para=" I was able to understand the basics of football, as well as get insight
        into the plays, terminology and strategies of my favorite teams."
                subtitle="Fitness Trainer, Spain National Team"
              />
            </SwiperSlide>
            <SwiperSlide>
              <CoachCard
                image="./assets/OTH-Index/testimonial-Roberto-Martinez.png"
                heading="Roberto Martinez"
                para="On The Hash is a great platform for football fans who want to dive deep into the game. Highly recommended!"
                subtitle="Head Coach, Belgian National Team"
              />
            </SwiperSlide>
          </Swiper>
          <BsArrowRightCircleFill
            className="sm:hidden next-slide "
            color="white"
            size={"1rem"}
          />
        </div> */}
        <Link
          to={"https://apps.apple.com/us/app/on-the-hash/id1503539977"}
          target="_blank"
        >
          <button className="bg-green-main p-2 my-5 font-bold rounded ">
            DOWNLOAD THE APP
          </button>
        </Link>
      </div>
    </div>
  );
};

export default LandingPage;
