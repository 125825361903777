export const cardData = [
  {
    image: "./assets/OTH-Index/card-Film-Analysis.png",
    heading: "Film Analysis",
    para: "Film Analysis is a feature of On The Hash which allows users to go in-depth with an expert into previous games and learn your favorite team’s strategies.",
  },
  {
    image: "./assets/OTH-Index/card-Knowledge-Test.png",
    heading: "Knowledge Test",
    para: "Subscribers will get regular quizzes to test their knowledge of football and their favorite team in particular. You choose the level of difficulty you wish to be tested.",
  },
  {
    image: "./assets/OTH-Index/card-Team-Strategies.png",
    heading: "Team Strategies",
    para: "Subscribers learn terminology, plays, how to read a defense and situational dynamics in a game, all from our content creators.",
  },
  {
    image: "./assets/OTH-Index/card-Play-Calls.png",
    heading: "Play Calls",
    para: "On The Hash offers fans an interactive way to learn and practice football strategies, terminology, and plays.",
  },
];

export const tagsData = [
  {
    title: "131",
    subtitle: "Teams",
  },
  {
    title: "550+",
    subtitle: "QUIZZES",
  },
  {
    title: "6M",
    subtitle: "REGISTERED USERS",
  },
  {
    title: "5M+",
    subtitle: "APP DOWNLOADS",
  },
  {
    title: "80M",
    subtitle: "UNIQUE FANS",
  },
  {
    title: "40+",
    subtitle: "SPORTS WORLDWIDE",
  },
];
export const brandImage = [
  "logo-1.png",
  "logo-2.png",
  "logo-3.png",
  "logo-4.png",
  "logo-5.png",
  "logo-6.png",
  "logo-7.png",
  "logo-8.png",
];
export const advertisedata = [
  {
    image: "sponsored-content.png",
    title: "SPONSORED CONTENT",
    subtitle:
      "Get in on original series featuring the best moments in high school sports.",
  },
  {
    image: "branded-experiences.png",
    title: "BRANDED EXPERIENCES",
    subtitle:
      "Together, we’ll create an experience designed to help teams and athletes win.",
  },
  {
    image: "native-video-in-feed.png",
    title: "NATIVE VIDEO IN-FEED",
    subtitle:
      "Reach athletes when they’re focused on improvement with in-feed native video.",
  },
  {
    image: "display-and-video.png",
    title: "DISPLAY & VIDEO",
    subtitle:
      "Connect with fans on team pages, athlete profiles and highlight videos.",
  },
];
